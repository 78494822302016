import {
   type TimetapLocationResponseDto,
   TimetapLocationType,
} from '@innerwell/dtos';
import { type QueryFunction, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

const useLocations = ({ state }: { state: string | null }) => {
   const fetchLocations: QueryFunction<
      TimetapLocationResponseDto[]
   > = async () => {
      if (!state) {
         throw new Error('Patients state is missing.');
      }

      const response = await webApiClient.timetap.getLocations({
         query: {
            state,
            // TODO: it was used before, but not anymore
            //  const locationTypeList = queryKey[1] as TimetapLocationType;
            // locationTypeList,
         },
      });
      return response.body;
   };

   const { data: locationsData, ...rest } = useQuery({
      queryKey: queryKeys.stateSchedulingLocations(state),
      queryFn: fetchLocations,
      enabled: Boolean(state),
   });

   const locations = useMemo(() => locationsData ?? [], [locationsData]);
   const inPersonLocations = useMemo(
      () =>
         locations.length
            ? locations.filter(
                 (l) => l.locationType === TimetapLocationType.Physical,
              )
            : null,
      [locations],
   );
   const onlineLocations = useMemo(
      () =>
         locations.length
            ? locations.filter(
                 (l) => l.locationType === TimetapLocationType.Virtual,
              )
            : null,
      [locations],
   );

   return {
      inPersonLocations,
      onlineLocations,
      locations,
      queryResult: rest,
   };
};

export default useLocations;
