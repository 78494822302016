import {
   type AppointmentWithTimetapItem,
   type ClinicianResponseDto,
   type CreateTimetapResponseDto,
   type EncounterTypeResponseDto,
   type MyPatientResponseDto,
   type Timeslot,
   type TimetapAppointmentResponseDto,
   type TimetapLocationResponseDto,
   TimetapLocationType,
   type WelkinEncounterTemplateNames,
} from '@innerwell/dtos';
import { WelkinRoleDb } from '@innerwell/prisma-app-generated';
import { getErrorMessage } from '@innerwell/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useAppointment } from '@/hooks/react-query/useAppointment';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import useLocations from '@/hooks/scheduling/useLocations';
import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';
import { handleSentryException, handleSentryMessage } from '@/utils/sentry';

import { type UseQueryMutation } from './cart-context';

import { queryKeys } from '@/types/query-keys';

type ClinicianWithTimetapId = ClinicianResponseDto & { staffId?: number };

// enum MyLocation {
//    PickLocation,
//    PickTimeSlot,
// }

export type AppointmentLoadingStatus = {
   type: 'reschedule';
   appointmentId: string;
};

const SchedulingContext = createContext<{
   appointment: AppointmentWithTimetapItem | null;
   isAppointmentFetched: boolean;
   service: EncounterTypeResponseDto | null;
   selectedLocation: Pick<
      TimetapLocationResponseDto,
      'locationId' | 'locationName' | 'locationType' | 'directions'
   > | null;
   selectedDate: Date | undefined;
   setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
   patient: MyPatientResponseDto | null;
   inPerson: {
      locations: TimetapLocationResponseDto[] | null;
   };
   online: {
      location: TimetapLocationResponseDto | null;
   };
   // staffAvailability: TimetapMultiStaffAvailabilityByMonthResultDto[] | null;
   // isStaffAvailabilityLoading: boolean;
   // serviceSchedulingBounds: RuleBasedBoundsResponseDto | null;
   selectedTimeslot: Timeslot | null;
   setSelectedTimeslot: React.Dispatch<React.SetStateAction<Timeslot | null>>;
   selectedClinician: ClinicianWithTimetapId | null;
   setSelectedClinician: React.Dispatch<
      React.SetStateAction<ClinicianWithTimetapId | null>
   >;
   canCreateAppointment: boolean;
   createAppointmentMutation: UseQueryMutation<
      CreateTimetapResponseDto,
      { forInsurance: boolean }
   >;
   updateAppointmentMutation: UseQueryMutation<
      TimetapAppointmentResponseDto,
      string
   >;
   welkinTemplateName: WelkinEncounterTemplateNames | null;
   appointmentLoadingStatus: AppointmentLoadingStatus | null;
   setAppointmentLoadingStatus: React.Dispatch<
      React.SetStateAction<AppointmentLoadingStatus | null>
   >;
   schedulingLocationType: TimetapLocationType | null;
   therapistTitle: string | null;
   isLocationsLoading: boolean;
}>({
   appointment: null,
   patient: null,
   isAppointmentFetched: false,
   service: null,
   selectedLocation: null,
   selectedDate: new Date(),
   setSelectedDate: () => {},
   inPerson: {
      locations: null,
   },
   online: {
      location: null,
   },
   selectedTimeslot: null,
   setSelectedTimeslot: () => {},
   selectedClinician: null,
   setSelectedClinician: () => {},
   canCreateAppointment: false,
   createAppointmentMutation: {} as UseQueryMutation<
      CreateTimetapResponseDto,
      { forInsurance: boolean }
   >,
   updateAppointmentMutation: {} as UseQueryMutation<
      TimetapAppointmentResponseDto,
      string
   >,
   welkinTemplateName: null,
   appointmentLoadingStatus: null,
   setAppointmentLoadingStatus: () => {},
   schedulingLocationType: null,
   therapistTitle: null,
   isLocationsLoading: true,
   // staffAvailability: [],
   // isStaffAvailabilityLoading: true,
   // serviceSchedulingBounds: null,
});

export const SchedulingProvider = ({
   children,
}: {
   children: React.ReactNode;
}) => {
   const { data: patient } = useMyPatient();
   const searchParams = useSearchParams();
   const pathname = usePathname();
   const { toastError } = useThemedToast();

   const queryClient = useQueryClient();

   const schedulingLocationType = useMemo(
      () =>
         pathname.includes('in-person')
            ? TimetapLocationType.Physical
            : TimetapLocationType.Virtual,
      [pathname],
   );

   const { templateName: paramsTemplateName, locationId: paramsLocationId } =
      useParams<{
         templateName: WelkinEncounterTemplateNames;
         locationId: string;
      }>();

   const [selectedDate, setSelectedDate] = useState<Date | undefined>();

   // Rescheduling
   const queryWelkinEncounterId = searchParams.get('id');
   const isReschedule = Boolean(queryWelkinEncounterId);

   const { appointment, isFetched: isAppointmentFetched } = useAppointment({
      appointmentId: queryWelkinEncounterId ?? undefined,
   });

   const [service, setService] = useState<EncounterTypeResponseDto | null>(
      null,
   );

   const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
      null,
   );

   const [selectedClinician, setSelectedClinician] =
      useState<ClinicianWithTimetapId | null>(null);

   useEffect(() => {
      setSelectedTimeslot(null);
   }, [selectedClinician?.id]);

   useEffect(() => {
      if (paramsTemplateName || appointment) {
         const templateName =
            paramsTemplateName ?? appointment?.appointmentTemplate;

         const fetchAndSetService = async () => {
            if (!templateName) {
               throw new Error('Template name is missing.');
            }

            setService(null);

            const service = await webApiClient.scheduling.getEncounterByName({
               params: {
                  encounterName: templateName,
               },
            });

            setService(service.body ?? null);
         };

         fetchAndSetService();
      }
   }, [appointment, paramsTemplateName]);

   const {
      locations,
      inPersonLocations,
      onlineLocations,
      queryResult: {
         isFetched: locationsFetched,
         isLoading: isLocationsLoading,
      },
   } = useLocations({
      state: patient?.state ?? null,
   });

   useEffect(() => {
      if (locationsFetched && (onlineLocations ?? []).length > 1) {
         toastError('Multiple virtual locations', 'Please contact a support');
         handleSentryMessage('Multiple virtual locations found', 'fatal', {
            'Patient ID': patient?.id,
            State: patient?.state,
         });
      }
   }, [
      locationsFetched,
      onlineLocations,
      patient?.id,
      patient?.state,
      toastError,
   ]);

   // Selected location from the url query
   const [selectedLocation, setSelectedLocation] = useState<Pick<
      TimetapLocationResponseDto,
      'locationId' | 'locationName' | 'locationType' | 'directions'
   > | null>(null);

   useEffect(() => {
      if (locations.length) {
         let location = null;

         // From regular scheduling
         if (paramsLocationId) {
            location = locations.find(
               (l) => l.locationId === parseInt(paramsLocationId),
            );
         }

         // From reschedule
         if (isReschedule) {
            location = appointment?.location;
         }

         if (location) {
            setSelectedLocation(location);
         }
      }
   }, [
      appointment?.location,
      locations,
      onlineLocations,
      paramsLocationId,
      isReschedule,
   ]);

   const canCreateAppointment = useMemo(() => {
      if (!selectedClinician || !selectedTimeslot) {
         return false;
      }

      return true;
   }, [selectedClinician, selectedTimeslot]);

   /**
    * Create appointment
    */
   const createAppointmentMutation = useMutation({
      mutationFn: async ({ forInsurance }: { forInsurance: boolean }) => {
         if (!service) {
            throw new Error('Service is missing.');
         }

         if (!selectedLocation) {
            throw new Error('Missing location.');
         }

         if (!selectedClinician?.staffId || !selectedTimeslot) {
            throw new Error('Missing clinician or timeslot.');
         }

         const response = await webApiClient.appointments.createTimetap({
            body: {
               locationId: selectedLocation.locationId,
               serviceId: service.encounterType.timetapId,
               staffId: selectedClinician.staffId,
               staff: selectedTimeslot.staff,
               forInsurance,
            },
         });

         return response.body;
      },
      onError: (err) => {
         if (axios.isAxiosError(err)) {
            toastError(
               err.response?.data?.message ??
                  'An error occurred while creating the appointment.',
            );
         }
         handleSentryException(err);
      },
      onSuccess: () => {
         queryClient.resetQueries({
            queryKey: queryKeys.upcomingAppointments,
         });
         queryClient.resetQueries({
            queryKey: queryKeys.isAppointmentReschedulableKey,
         });
         // TODO(AHC-205): refresh appt bank after some time? It will be updated
         // on Welkin hook, so not immediately after the appointment is created.
         queryClient.resetQueries({
            queryKey: queryKeys.appointmentBank,
         });
      },
   });

   /**
    * Reschedule/Update appointment
    */
   const updateAppointmentMutation = useMutation({
      mutationFn: async (timetapEncounterId: string) => {
         if (!service) {
            throw new Error('Service is missing.');
         }

         if (!selectedLocation) {
            throw new Error('Missing location.');
         }

         if (!selectedClinician?.staffId || !selectedTimeslot) {
            throw new Error('Missing clinician or timeslot.');
         }

         const response = await webApiClient.appointments.updateTimetap({
            params: {
               id: timetapEncounterId,
            },
            body: {
               client: selectedTimeslot.client,
               staff: selectedTimeslot.staff,
            },
         });

         return response.body;
      },
      onError: (err) => {
         toastError(getErrorMessage(err));

         handleSentryException(err);
      },
      onSuccess: () => {
         queryClient.invalidateQueries({
            queryKey: queryKeys.upcomingAppointments,
         });
         queryClient.resetQueries({
            queryKey: queryKeys.isAppointmentReschedulableKey,
         });
         queryClient.resetQueries({
            queryKey: queryKeys.appointmentBank,
         });
      },
   });

   const [appointmentLoadingStatus, setAppointmentLoadingStatus] =
      useState<AppointmentLoadingStatus | null>(null);

   return (
      <SchedulingContext.Provider
         value={{
            appointment: appointment ?? null,
            isAppointmentFetched,
            service,
            selectedLocation,
            selectedDate,
            setSelectedDate,
            patient: patient ?? null,
            inPerson: {
               locations: inPersonLocations ?? [],
            },
            online: {
               location: _.first(onlineLocations) ?? null,
            },
            // staffAvailability: staffAvailability ?? null,
            // isStaffAvailabilityLoading: isStaffAvailabilityByMonthLoading,
            // serviceSchedulingBounds: serviceSchedulingBounds ?? null,
            selectedTimeslot,
            setSelectedTimeslot,
            selectedClinician,
            setSelectedClinician,
            canCreateAppointment,
            createAppointmentMutation,
            updateAppointmentMutation,
            therapistTitle: service
               ? service.encounterType.role ===
                 WelkinRoleDb.PrescribingClinician
                  ? 'clinician'
                  : 'therapist'
               : null,
            welkinTemplateName: paramsTemplateName ?? null,
            appointmentLoadingStatus,
            setAppointmentLoadingStatus,
            schedulingLocationType,
            isLocationsLoading,
         }}
      >
         {children}
      </SchedulingContext.Provider>
   );
};

/**
 * Hook to use the scheduling context
 */
export const useScheduling = () => {
   const context = useContext(SchedulingContext);

   return context;
};
