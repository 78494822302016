'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import { useSession } from '@/contexts/session-context';
import formsortLocalStorage from '@/utils/formsortLocalStorage';

export const SetSentryUser = () => {
   const { status, data } = useSession();
   useEffect(() => {
      if (status !== 'loading') {
         const formsortUUID =
            status === 'authenticated'
               ? (data['custom:formsortUUID'] ?? data['custom:welkinUserId'])
               : formsortLocalStorage.getUUID() || 'Unknown';

         Sentry.setUser({
            id:
               status === 'authenticated'
                  ? data['cognito:username']
                  : formsortUUID,
            formsortUUID,
         });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [status]);

   return null;
};
