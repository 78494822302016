import { type SignupScreeningAnswers } from '@innerwell/dtos';
import { DateTime } from 'luxon';

const LOCALSTORAGE_UUID_KEY = 'formsort-uuid';
const LOCALSTORAGE_INITIAL_SCREENING_ANSWERS_KEY =
   'formsort-initial-screening-answers';

const formsortLocalStorage = {
   setUUID: (uuid: string) => {
      window.localStorage.setItem(LOCALSTORAGE_UUID_KEY, uuid);
   },

   getUUID: () => {
      return window.localStorage.getItem(LOCALSTORAGE_UUID_KEY);
   },

   checkIfUUIDExists: () => {
      return window.localStorage.getItem(LOCALSTORAGE_UUID_KEY);
   },

   setInitialScreeningAnswers: (answers: Record<string, unknown>) => {
      return window.localStorage.setItem(
         LOCALSTORAGE_INITIAL_SCREENING_ANSWERS_KEY,
         JSON.stringify(answers),
      );
   },
   getInitialScreeningAnswers: () => {
      const str = globalThis.localStorage?.getItem(
         LOCALSTORAGE_INITIAL_SCREENING_ANSWERS_KEY,
      );
      if (str) {
         try {
            return JSON.parse(str) as SignupScreeningAnswers;
         } catch (e) {
            // ignore the error
         }
      }
      return null;
   },
};

export const getDobFromInitialScreeningAnswers = (
   answers: SignupScreeningAnswers,
) => {
   try {
      const month = answers['user-dob_month'];
      const day = answers['user-dob_day'];
      const year = answers['user-dob_year'];

      if (month && day && year) {
         return DateTime.fromObject(
            {
               month,
               day,
               year,
            },
            { zone: 'utc' },
         );
      }
   } catch (e) {
      // noop
   }
   return null;
};

export default formsortLocalStorage;
