'use client';

import { useQuery } from '@tanstack/react-query';

import { ampli } from '../ampli/Ampli';

import { isServer } from '@/services/env/utils/runtime-environments';
import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

export const AmplitudeAnalytics = () => {
   useQuery({
      enabled:
         !isServer() &&
         !ampli.isLoaded &&
         !!getClientPublicRuntimeConfig().amplitudeApiKey,
      queryKey: ['analytics', 'amplitude'],
      queryFn: () => {
         const amplitude = ampli.load({
            client: {
               apiKey: getClientPublicRuntimeConfig().amplitudeApiKey,
               configuration: {
                  defaultTracking: {
                     pageViews: true,
                     sessions: true,
                     formInteractions: true,
                     attribution: true,
                  },
               },
            },
         });

         ampli.client.add(sessionReplayPlugin());

         return amplitude;
      },
      notifyOnChangeProps: ['data'],
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
   });

   return null;
};
