import { type MyPatientResponseDto } from '@innerwell/dtos';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';
import { webApiClient } from '@/api-client/apiClient';

export default function useMyPatient(
   opts: Omit<
      UseQueryOptions<MyPatientResponseDto>,
      'queryKey' | 'queryFn' | 'initialData'
   > = {},
) {
   const { data: session } = useSession();

   return useQuery({
      queryKey: queryKeys.patient,
      queryFn: async () => {
         const response = await webApiClient.account.getPatient();
         return response.body;
      },
      enabled: !!session,
      staleTime: 1000 * 60 * 5,
      ...opts,
   });
}
