import * as amplitude from '@amplitude/analytics-browser';

import {
   Ampli,
   type PromiseResult,
   type EventOptions,
   type Result,
   type Event,
} from './codegen';

export class AmpliUser extends Ampli {
   private identifyPayload: amplitude.Identify = new amplitude.Identify();
   private hasNewIdentifyData = false;

   override track(event: Event, options?: EventOptions): PromiseResult<Result> {
      if (this.hasNewIdentifyData) {
         this.client.identify(this.identifyPayload);
         this.identifyPayload = new amplitude.Identify();
         this.hasNewIdentifyData = true;
      }
      return super.track(event, options);
   }

   setUserProperties(userProperties: object) {
      Object.entries(userProperties).forEach(([key, value]) => {
         this.identifyPayload.set(key, value);
         this.hasNewIdentifyData = true;
      });
   }
}

export const ampli = new AmpliUser();
