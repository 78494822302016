'use client';

import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from '@innerwell/chakra/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import localFont from 'next/font/local';

import { CartProvider } from '@/contexts/cart-context';
import { PatientProgramProvider } from '@/contexts/patient-program-context';
import { SchedulingProvider } from '@/contexts/scheduling-context';
import { SessionProvider } from '@/contexts/session-context';
import { queryClient } from '@/services/react-query/query-client';

const cannonFont = localFont({
   src: [
      {
         path: '../assets/fonts/Cannon/Cannon-Regular.woff2',
         weight: '400',
         style: 'normal',
      },
   ],
});

const ttCommonsProFont = localFont({
   src: [
      {
         path: '../assets/fonts/TTCommons/TTCommonsProRg/font.woff2',
         weight: '400',
         style: 'normal',
      },
      {
         path: '../assets/fonts/TTCommons/TTCommonsProMd/font.woff2',
         weight: '500',
         style: 'normal',
      },
      {
         path: '../assets/fonts/TTCommons/TTCommonsProDb/font.woff2',
         weight: '600',
         style: 'normal',
      },
   ],
});

customTheme.fonts.body = ttCommonsProFont.style.fontFamily;
customTheme.fonts.heading = cannonFont.style.fontFamily;

export const Providers = ({ children }: { children: React.ReactNode }) => {
   return (
      <QueryClientProvider client={queryClient}>
         <SessionProvider>
            <ChakraProvider theme={customTheme}>
               <CartProvider>
                  <SchedulingProvider>
                     <PatientProgramProvider>{children}</PatientProgramProvider>
                  </SchedulingProvider>
               </CartProvider>
            </ChakraProvider>
         </SessionProvider>
      </QueryClientProvider>
   );
};
