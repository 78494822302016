'use client';

import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

export const TagManagerInit = () => {
   useEffect(() => {
      if (
         process.env.NODE_ENV === 'production' ||
         getClientPublicRuntimeConfig().loadGtm
      ) {
         TagManager.initialize({
            gtmId: getClientPublicRuntimeConfig().gtmId,
         });
      }
   }, []);

   return null;
};
