import {
   type WelkinEncounterTemplateNames,
   WelkinPatientProgramStatus,
   type WelkinProgramPhases,
   type WelkinPrograms,
} from '@innerwell/dtos';
import { useQuery } from '@tanstack/react-query';
import {
   createContext,
   type ReactNode,
   useCallback,
   useContext,
   useEffect,
   useState,
} from 'react';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { updateProgramPhaseScheduleMap } from '@/utils';
import { handleSentryMessage } from '@/utils/sentry';

import { queryKeys } from '@/types/query-keys';

export type WelkinProgramPhase =
   | {
        program: WelkinPrograms;
        phase: WelkinProgramPhases;
     }
   | {
        program: null;
        phase: null;
     };

export interface PathHistoryWithTimestamp {
   program: WelkinPrograms;
   phase: WelkinProgramPhases;
   timestamp: string;
}

const PatientProgramContext = createContext<{
   programPhase: WelkinProgramPhase;
   updateProgramPhase: (prop: WelkinProgramPhase) => Promise<void>;
   updateProgramPhaseAfterSchedule: (
      encounterTemplateName: WelkinEncounterTemplateNames,
   ) => void;
   pathHistory: PathHistoryWithTimestamp[];
}>({
   programPhase: {
      program: null,
      phase: null,
   },
   updateProgramPhase: async () => {},
   updateProgramPhaseAfterSchedule: () => {},
   pathHistory: [],
});

export const PatientProgramProvider = ({
   children,
}: {
   children: ReactNode;
}) => {
   const [programPhase, setProgramPhase] = useState<WelkinProgramPhase>({
      program: null,
      phase: null,
   });

   const [pathHistory, updatePathHistory] = useState<
      PathHistoryWithTimestamp[]
   >([]);

   const { data: sessionData } = useSession();
   const { toastError } = useThemedToast();

   const { data: programData } = useQuery({
      queryKey: queryKeys.programs,
      queryFn: async () => {
         const { body } = await webApiClient.programs.getAssignedPrograms();
         return body.data;
      },
      enabled: !!sessionData,
      refetchInterval: 10_000,
   });

   useEffect(() => {
      if (programData) {
         const getProgramAndPhase = async () => {
            const welkinCurrentPrograms = programData.filter(
               (p) => p.status === WelkinPatientProgramStatus.InProgress,
            );

            if (welkinCurrentPrograms.length > 1) {
               toastError(
                  'You seem to be assigned to 2 programs. Please contact support to resolve this',
               );
               handleSentryMessage(
                  'A patient is assigned to 2 programs!',
                  'fatal',
                  {
                     'Patient ID': welkinCurrentPrograms[0].patientId,
                     'Assigned programs': welkinCurrentPrograms.map(
                        (p) => p.programName,
                     ),
                  },
               );
            }

            const welkinCurrentProgram = welkinCurrentPrograms.at(0);
            if (welkinCurrentProgram) {
               const allHistory = programData
                  .map((program) => {
                     return program.pathHistory.map(
                        ({ name: phase, timestamp }) => ({
                           program: program.programName,
                           phase,
                           timestamp,
                        }),
                     );
                  })
                  .flat();

               updatePathHistory(allHistory);

               const currentProgramName = welkinCurrentProgram.programName;
               const currentPhaseName = welkinCurrentProgram.currentPhase.name;
               setProgramPhase({
                  program: currentProgramName,
                  phase: currentPhaseName,
               });
            } else {
               setProgramPhase({
                  program: null,
                  phase: null,
               });
            }
         };

         getProgramAndPhase();
      }
   }, [programData, toastError]);

   const updateProgramPhase = useCallback(
      async (newPhase: WelkinProgramPhase) => {
         if (newPhase.program && newPhase.phase && sessionData) {
            setProgramPhase(newPhase);
            updatePathHistory((prev) => [
               ...prev,
               {
                  program: newPhase.program,
                  phase: newPhase.phase,
                  timestamp: new Date().toISOString(),
               },
            ]);
            await webApiClient.programs.updateProgram({
               params: {
                  progName: newPhase.program,
               },
               body: {
                  phaseName: newPhase.phase,
               },
            });
         } else {
            setProgramPhase(newPhase);
         }
      },
      [sessionData],
   );

   const updateProgramPhaseAfterSchedule = useCallback(
      (encounterTemplateName: WelkinEncounterTemplateNames) => {
         if (programPhase.program) {
            const newPhase = updateProgramPhaseScheduleMap(
               programPhase.program,
               programPhase.phase,
               encounterTemplateName,
            );

            if (newPhase) {
               updateProgramPhase({
                  program: programPhase.program,
                  phase: newPhase,
               });
            }
         }
      },
      [programPhase, updateProgramPhase],
   );

   return (
      <PatientProgramContext.Provider
         value={{
            programPhase,
            updateProgramPhase,
            updateProgramPhaseAfterSchedule,
            pathHistory,
         }}
      >
         {children}
      </PatientProgramContext.Provider>
   );
};

export const usePatientProgram = () => {
   const context = useContext(PatientProgramContext);
   return context;
};
